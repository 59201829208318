<template>
  <v-autocomplete
    v-model="category"
    :items="categories"
    :loading="loading"
    :search-input.sync="search"
    :label="$tc('category', 1) | capitalize"
    small-chips
    cache-items
    dense
    multiple
    clearable
    menu-props="offsetY"
    item-text="name"
    item-value="id"
    @change="$emit('update:value', category)"
  >
    <template #prepend-inner>
      <v-icon
        small
        class="mt-1"
        color="grey"
      >
        fal fa-folder-open
      </v-icon>
    </template>
    <!-- <template #selection="{ item }">
      <v-chip
        x-small
        class="ml-0"
      >
        {{ item.name.en }}
      </v-chip>
    </template> -->
  </v-autocomplete>
</template>
<script>
  export default {
    props: {
      value: {
        type: Array,
        default: () => [],
      },
      type: {
        type: String,
        default: null,
      },
    },
    data () {
      return {
        loading: false,
        search: null,
        category: this.value,
        categories: [],
      }
    },
    watch: {
      search () {
        this.fetchCategories()
      },
      value () {
        this.category = this.value
      },
    },
    created () {
      this.fetchCategories()
    },
    methods: {
      fetchCategories () {
        this.loading = true
        const promise = this.axios.get('admin/organisms/categories', {
          params:
            {
              count: 20,
              search: this.search,
              type: this.type,
            },
        })
        return promise.then((response) => {
          this.categories = response.data.data
        }).catch(error => {
          this.$toast.error(error.response?.data?.message)
        }).finally(() => {
          this.loading = false
        })
      },
    },
  }
</script>
